// 经典案例
<template>
  <div class="news-lists-content">
    <el-row class="news-list">
      <el-col class="news-item back-border" :span="24" v-for="item in news" :key="item.id">
        <router-link class="news-info justify-between-center"
          :to="{path:'/news/newsDetails',query:{type:$route.name,id:item.id}}">
          <p class="news-title text-ellipsis">{{item.biaoti}}</p>
          <p class="news-time">{{item.created_at|filterTime}}</p>
        </router-link>
      </el-col>
    </el-row>
    <Paginations v-if="total" :isAll="true" :total="total" :pageSize="pageSize" @changePage="changePage" />
  </div>
</template>

<script>
import Paginations from '@/components/Paginations.vue'
import { getNews } from '@/api'
export default {
  components: {
    Paginations
  },
  data () {
    return {
      news: [],
      total: 0, // 总条数
      pageSize: 12, // 每页展示多少条
    };
  },
  computed: {

  },
  created () {

  },
  mounted () {
    this.getNews(this.$route.name, 1)
  },
  methods: {
    async getNews (type, page) {
      try {
        let newsRes = await getNews({ type, page })
        this.news = newsRes.data.data
        if (this.news.length) {
          this.total = newsRes.data.num
          this.pageSize = newsRes.data.page_size
        } else {
          this.total = 0
        }
      } catch (error) {

      }
    },
    changePage (page) {
      this.getNews(this.$route.name, page)
    }
  },

};
</script>

<style scoped lang="less">
.news-list {
  min-height: 500px;
}
.news-item {
  padding: 0 20px;
  height: 78px;
  margin-bottom: 14px;
  .news-info {
    position: relative;
    .news-title {
      max-width: 90%;
      position: absolute;
      left: 0;
      font-size: 18px;
      font-weight: bold;
    }
    .news-time {
      position: absolute;
      right: 0;
      font-size: 14px;
      color: #999999;
    }
  }
}
</style>